<template>
  <div>
    <header class="py-4 text-center header">
      <img class="inline-block logo" src="@/assets/logo-banner-green.png" alt="Rafflebox" />
    </header>
    <b-container>
      <b-row class="vh-100">
        <b-col sm="12" md="12" lg="8" offset-lg="2" xl="6" offset-xl="3">
          <div class="py-8">
            <h1 class="font-display font-bold text-5xl pb-0">Rafflebox Sign-up</h1>
            <Alert v-if="success" variant="green" class="mt-8">
              <p>Thank you for submitting the required information to set up your raffle!</p>
              <p>
                Our team will review in the next 1-3 business days and reach out with next steps. A confirmation email
                has been sent to the account owner listed in the form.
              </p>
            </Alert>

            <b-form v-else class="org-signup-form" @submit="onSubmit">
              <div class="py-2 org-info">
                <h2 class="font-display fony-medium text-3xl">Organization Information</h2>
                <b-form-group :invalid-feedback="veeErrors.first('org-name')">
                  <!-- Character limit comes from QBO sync. Max 100 characters -->
                  <label for="org-name" class="mb-1">Organization Name <span class="text-danger">*</span></label>
                  <b-form-input
                    id="org-name"
                    name="org-name"
                    v-model="organization.name"
                    v-validate="{ required: true, min: 3, max: 100 }"
                    :state="validateState('org-name')"
                    aria-describedby="org-name-feedback"
                    data-vv-as="organization name"
                    placeholder="Rafflebox"
                    trim
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group :invalid-feedback="veeErrors.first('org-legal-name')">
                  <!-- Character limit comes from QBO sync. Max 100 characters -->
                  <label for="org-legal-name" class="mb-0">
                    Organization Legal Name <span class="text-danger">*</span>
                  </label>
                  <b-form-text class="mt-0 mb-1">Enter the name as it appears on your lottery licence</b-form-text>
                  <b-form-input
                    id="org-legal-name"
                    name="org-legal-name"
                    v-model="organization.legalName"
                    v-validate="{ required: true, min: 3, max: 100 }"
                    :state="validateState('org-legal-name')"
                    aria-describedby="org-legal-name-feedback"
                    data-vv-as="organization legal name"
                    placeholder="Rafflebox Technologies Inc."
                    trim
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <div class="py-2 account-owner-information">
                <h2 class="font-display fony-medium text-3xl pb-0">Account Owner Information</h2>
                <p class="mb-2">Additional users can be added to the account after sign-up.</p>
                <b-form-row>
                  <b-col sm="12" md="6">
                    <b-form-group :invalid-feedback="veeErrors.first('first-name')">
                      <label for="first-name" class="mb-1">First Name <span class="text-danger">*</span></label>
                      <b-form-input
                        id="first-name"
                        name="first-name"
                        v-model="organization.contact.firstName"
                        v-validate="{ required: true, min: 2 }"
                        :state="validateState('first-name')"
                        aria-describedby="first-name-feedback"
                        data-vv-as="first name"
                        placeholder="James"
                        trim
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group :invalid-feedback="veeErrors.first('last-name')">
                      <label for="last-name" class="mb-1">Last Name <span class="text-danger">*</span></label>
                      <b-form-input
                        id="last-name"
                        name="last-name"
                        v-model="organization.contact.lastName"
                        v-validate="{ required: true, min: 2 }"
                        :state="validateState('last-name')"
                        aria-describedby="last-name-feedback"
                        data-vv-as="last name"
                        placeholder="Jones"
                        trim
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="12" md="6">
                    <EmailInput
                      ref="email"
                      label="Email"
                      name="email"
                      :required="true"
                      placeholder="jones@example.com"
                      v-model="organization.contact.email"
                    />
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group :invalid-feedback="veeErrors.first('phone')">
                      <label for="phone" class="mb-1">Phone Number <span class="text-danger">*</span></label>
                      <b-form-input
                        id="phone"
                        name="phone"
                        v-model="organization.billing.phone"
                        v-validate="{ required: true, phoneNumber: true }"
                        :state="validateState('phone')"
                        placeholder="(555) 555 5555"
                        class="input-field"
                        v-mask="'(###) ### ####'"
                        data-vv-as="phone number"
                        trim
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label class="mb-0 block">Rafflebox account contact <span class="text-danger">*</span></label>
                      <b-form-input
                        id="sales-rep"
                        name="sales-rep"
                        v-model="salesRepName"
                        :state="validateSalesRep()"
                        required
                        disabled
                      ></b-form-input>
                      <b-form-invalid-feedback class="" :state="validateSalesRep()">
                        There is an error on this field. Please
                        <a href="mailto:help@rafflebox.ca" style="color: #dc3545; text-decoration: underline"
                          >contact support</a
                        >.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </div>

              <div class="billing">
                <h2 class="font-display fony-medium text-3xl pb-0">Billing Information</h2>
                <b-form-row>
                  <b-col>
                    <b-form-group :invalid-feedback="veeErrors.first('country')">
                      <label class="mb-1 block">Country <span class="text-danger">*</span></label>
                      <b-form-select
                        v-model="organization.billing.countryCode"
                        id="country"
                        name="country"
                        class="mb-0"
                        v-validate="{ required: true }"
                        :state="validateState('country')"
                        required
                      >
                        <b-form-select-option :value="null">Select a country</b-form-select-option>
                        <b-form-select-option value="CA">Canada</b-form-select-option>
                        <b-form-select-option value="US">United States</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row v-if="countryFieldDisplay">
                  <b-col>
                    <b-form-group :invalid-feedback="veeErrors.first('street-address')">
                      <label for="street-address" class="mb-1">Street Address <span class="text-danger">*</span></label>
                      <b-form-input
                        id="street-address"
                        name="street-address"
                        v-model="organization.billing.addressLine"
                        v-validate="{ required: true, min: 3 }"
                        :state="validateState('street-address')"
                        aria-describedby="street-address-feedback"
                        data-vv-as="street address"
                        placeholder="123 Main Street"
                        trim
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :invalid-feedback="veeErrors.first('city')">
                      <label for="city" class="mb-1">City <span class="text-danger">*</span></label>
                      <b-form-input
                        id="city"
                        name="city"
                        v-model="organization.billing.city"
                        v-validate="{ required: true, min: 3 }"
                        :state="validateState('city')"
                        aria-describedby="street-address-feedback"
                        data-vv-as="city"
                        placeholder="Springfield"
                        trim
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group v-show="countryFieldDisplay === 'CA'" :invalid-feedback="veeErrors.first('province')">
                      <label for="province" class="mb-1"
                        >{{ provinceView.dropdownLabel }} <span class="text-danger">*</span>
                      </label>
                      <b-form-select
                        id="province"
                        name="province"
                        v-model="organization.billing.provinceState"
                        :options="provinceOptions"
                        v-validate="{ required: true }"
                        :state="validateState('province')"
                        data-vv-as="province"
                      >
                        <template #first>
                          <b-form-select-option :value="null">Select a Province</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group v-show="countryFieldDisplay === 'US'" :invalid-feedback="veeErrors.first('state')">
                      <label for="state" class="mb-1"
                        >{{ stateView.dropdownLabel }} <span class="text-danger">*</span>
                      </label>
                      <b-form-select
                        id="state"
                        name="state"
                        v-model="organization.billing.provinceState"
                        :options="stateOptions"
                        v-validate="{ required: true }"
                        :state="validateState('state')"
                        data-vv-as="state"
                      >
                        <template #first>
                          <b-form-select-option :value="null">Select a State</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <PostalInput
                      v-if="this.countryFieldDisplay === 'CA'"
                      ref="postalCode"
                      label="Postal Code"
                      name="postal-code"
                      :province="organization.billing.provinceState"
                      v-model="organization.billing.postalZipCode"
                      :required="countryFieldDisplay === 'CA'"
                      placeholder="A1A 1A1"
                    />
                    <ZipInput
                      v-if="this.countryFieldDisplay === 'US'"
                      ref="zipCode"
                      label="ZIP Code"
                      name="postal-code"
                      v-model="organization.billing.postalZipCode"
                      :required="countryFieldDisplay === 'US'"
                    />
                  </b-col>
                </b-form-row>
              </div>

              <div class="error-wrap">
                <Alert v-if="errorMessage" variant="red">
                  There was an error with your submission. Please review all fields and ensure you have entered the
                  correct information. If you continue to experience issues submitting, please reach out to
                  <a href="mailto:help@rafflebox.ca">help@rafflebox.ca.</a>.
                </Alert>
              </div>

              <div class="submit-wrap mt-4 text-right">
                <RbButton variant="success" :loading="busy" @click.native="onSubmit">Submit</RbButton>
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import EmailInput from '@/components/inputs/EmailInput';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput';

import ProvinceStateService from '@/lib/province-state-view-service';
import OrganizationServiceV2 from '@/lib/organization-service-v2';

export default {
  components: { Alert, EmailInput, PostalInput, ZipInput },
  data() {
    return {
      success: false,
      errorMessage: null,
      busy: false,
      salesRepName: null,
      salesRepUuid: null,
      countryFieldDisplay: null,
      provinceView: {},
      provinceOptions: {},
      stateView: {},
      stateOptions: {},
      organization: {
        name: null,
        legalName: null,
        contact: {
          firstName: null,
          lastName: null,
          email: null
        },
        billing: {
          addressLine: null,
          city: null,
          phone: null,
          provinceState: null,
          countryCode: null,
          postalZipCode: null
        },
        salesRepId: null
      }
    };
  },
  created() {
    // Set Province and State from service
    this.provinceView = ProvinceStateService.getDefaultView('CA');
    this.provinceOptions = this.provinceView.dropdownOptions;

    this.stateView = ProvinceStateService.getDefaultView('US');
    this.stateOptions = this.stateView.dropdownOptions;
  },
  mounted() {
    // Grab Sales Rep name and uuid from query params
    this.salesRepName = this.$route.query.salesrepname;
    this.salesRepId = this.$route.query.salesrepid;

    if (this.salesRepId) {
      this.organization.salesRepId = this.salesRepId;
    }
  },
  watch: {
    'organization.billing.countryCode': function (newVal) {
      this.countryFieldDisplay = newVal;
      // Clear shared values on switch
      this.organization.billing.provinceState = null;
      this.organization.billing.postalZipCode = null;
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      const emailValid = this.$refs.email.isValid();

      // Country needs to be selected for the validation to be triggered otherwise block
      let postalValid = false;
      if (this.countryFieldDisplay) {
        if (this.countryFieldDisplay === 'US') {
          postalValid = this.$refs.zipCode.isValid();
        } else {
          postalValid = this.$refs.postalCode.isValid();
        }
      }

      // Phone number formatted to 10 digits
      if (this.organization.billing.phone) {
        this.organization.billing.phone = this.organization.billing.phone.replace(/\D/g, '');
      }

      // Postal and Zip codes have no spaces
      if (this.organization.billing.postalZipCode) {
        this.organization.billing.postalZipCode = this.organization.billing.postalZipCode.replace(/\s/g, '');
      }

      if (valid && emailValid && postalValid) {
        this.submitForm();
      }
    },
    async submitForm() {
      try {
        this.busy = true;
        await OrganizationServiceV2.organizationSignup({ ...this.organization });
        this.success = true;
      } catch (error) {
        this.errorMessage = true;
      } finally {
        this.busy = false;
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateSalesRep() {
      const repName = this.salesRepName ? true : false;
      const repUuid = this.salesRepId ? true : false;

      return repName && repUuid;
    }
  }
};
</script>

<style scoped>
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.5);
}
.container {
  margin: auto;
}
.logo {
  width: 315px;
}
</style>

<style lang="scss">
.org-signup-form {
  input,
  select {
    background-color: #f4f5f5 !important;
  }

  .email-input input {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0.25rem !important;
  }

  input::placeholder,
  select::placeholder {
    color: #bbb;
  }

  .custom-select.is-invalid,
  .was-validated .custom-select:invalid,
  .custom-select.is-valid,
  .was-validated .custom-select:valid {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 1rem center/8px 10px no-repeat;
  }

  .form-control.is-valid,
  .was-validated .form-control:valid,
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none;
  }

  .btn-success {
    text-shadow: 0px 0px 5px #3a8641;
  }

  .rb-alert__msg {
    a {
      font-weight: bold;
      color: #a61b1b;
    }
  }
}
</style>
